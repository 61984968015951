html {
  height: 100%;
}

body {
  height: 100%;
  margin: 0; 
  padding: 0;
  font-family: Century-Gothic;
  src: local('Century-Gothic'), url(assets/fonts/Century-Gothic.ttf) format('truetype'); 
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root{
  height: 100%;
}

 
